<table
    data-testid="land-registry-result-table"
    mat-table
    multiTemplateDataRows
    [dataSource]="titlesSource"
    class="collection__table lr-result-table"
>
    <!-- Reference Column -->
    <ng-container matColumnDef="reference">
        <th
            mat-header-cell
            *matHeaderCellDef
            [style.width]="columnWidthsMap?.reference"
        >
            Reference
        </th>
        <td
            mat-cell
            *matCellDef="let element"
        >
            {{ element.titleNumber }}
        </td>
    </ng-container>

    <!-- Type Column -->
    <ng-container matColumnDef="type">
        <th
            mat-header-cell
            *matHeaderCellDef
            [style.width]="columnWidthsMap?.type"
        >
            Type
        </th>
        <td
            mat-cell
            *matCellDef="let element"
        >
            {{ element.ownership }}
        </td>
    </ng-container>

    <!-- Address Column -->
    <ng-container matColumnDef="address">
        <th
            mat-header-cell
            *matHeaderCellDef
            [style.width]="columnWidthsMap?.address"
        >
            Address
        </th>
        <td
            mat-cell
            *matCellDef="let element"
        >
            {{ element.address }}
        </td>
    </ng-container>

    <!-- what3words Column -->
    <ng-container matColumnDef="what3words">
        <th
            mat-header-cell
            *matHeaderCellDef
            [style.width]="columnWidthsMap?.what3words"
        >
            what3words
        </th>
        <td
            mat-cell
            *matCellDef="let element"
        >
            {{ joinIfArray(element.what3words) }}
        </td>
    </ng-container>

    <!-- Proprietors Column -->
    <ng-container matColumnDef="proprietors">
        <th
            mat-header-cell
            *matHeaderCellDef
            [style.width]="columnWidthsMap?.proprietors"
        >
            Proprietor(s)
        </th>
        <td
            mat-cell
            *matCellDef="let element"
        >
            {{
                element.proprietors.length < 100
                    ? element.proprietors
                    : (element.proprietors | slice:0:70) + '...'
            }}
        </td>
    </ng-container>

    <!-- Right Type Column -->
    <ng-container matColumnDef="rightType">
        <th
            mat-header-cell
            *matHeaderCellDef
            [style.width]="columnWidthsMap?.rightType"
        >
            Right Type
        </th>
        <td
            mat-cell
            *matCellDef="let element"
            [class.capitalize-first-letter]="element.rightType"
        >
            {{ element.rightType || 'N/A' }}
        </td>
    </ng-container>

    <!-- Right Description Column -->
    <ng-container matColumnDef="rightDescription">
        <th
            mat-header-cell
            *matHeaderCellDef
            [style.width]="columnWidthsMap?.rightDescription"
        >
            Right Description
        </th>
        <td
            mat-cell
            *matCellDef="let element"
            [style.width]="columnWidthsMap?.rightDescription"
        >
            <div
                #rightDescriptionContainer
                class="right-description-text text-truncate"
            >
                {{ element.rightDescription || 'N/A' }}
                <button
                    *ngIf="rightDescriptionContainer.scrollHeight - rightDescriptionContainer.clientHeight > 0"
                    class="clear-btn-styles show-more-button"
                    (click)="onExpandRightDescriptionClick(element)"
                >
                    <svg
                        class="show-more-icon"
                        [class.show-more-icon--spin-up]="isRightDescriptionExpanded(element)"
                        viewBox="0 0 10 5"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g
                            fill-rule="evenodd"
                            stroke="none"
                            stroke-width="1"
                            fill="#979797"
                        >
                            <path d="M0,0 L5,5 L10,0 L0,0 Z"/>
                        </g>
                    </svg>
                </button>
            </div>
        </td>
    </ng-container>

    <!-- Company Number Column -->
    <ng-container matColumnDef="companyNumbers">
        <th
            mat-header-cell
            *matHeaderCellDef
            [style.width]="columnWidthsMap?.companyNumbers"
        >
            Company Number(s)
        </th>
        <td
            mat-cell
            *matCellDef="let element"
        >
            {{
                element.companyNumbers?.length < 100
                    ? element.companyNumbers
                    : (element.companyNumbers | slice:0:70) + '...'
            }}
        </td>
    </ng-container>

    <!-- Select Column -->
    <ng-container matColumnDef="select">
        <th
            mat-header-cell
            *matHeaderCellDef
            [style.width]="columnWidthsMap?.select"
        >
            <label
                *ngIf="titles.length"
                class="collection__add-all-control"
            >
                <input
                    type="checkbox"
                    class="checkbox-input"
                    (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                >
                <mat-icon svgIcon="add-all-checkbox"></mat-icon>
                <span class="checkbox-text">Add All</span>
            </label>
        </th>
        <td
            mat-cell
            *matCellDef="let row"
        >
            <label class="collection__add-control">
                <input
                    type="checkbox"
                    class="checkbox-input"
                    (click)="$event.stopPropagation()"
                    (change)="$event ? toggleSelection(row) : null"
                    [checked]="isSelected(row)"
                >
                <div class="collection__add-label"></div>
            </label>
        </td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
        <td
            mat-cell
            *matCellDef="let element"
            [attr.colspan]="columns.length"
        >
            <div
                *ngIf="!isLoading"
                class="right-description-row"
                [@detailExpand]="isRightDescriptionExpanded(element) ? 'expanded' : 'collapsed'"
            >
                <div class="right-description-row__title">
                    Right Description
                </div>
                <p class="right-description-row__content">
                    {{ element.rightDescription }}
                </p>
            </div>
        </td>
    </ng-container>

    <tr
        mat-header-row
        *matHeaderRowDef="columnNames"
    ></tr>
    <tr
        mat-row
        [style.height.px]="65"
        [hidden]="isLoading"
        *matRowDef="let row; columns: columnNames;"
    ></tr>
    <tr
        mat-row
        *matRowDef="let row; columns: ['expandedDetail']"
        class="details-expandable-row"
    ></tr>
</table>
<avl-table-loading-placeholder
    *ngIf="isLoading"
    [columns]="columnNames"
    [columnWidths]="columnWidths"
    [size]="tableRowsAmount"
    [rowHeight]="'62px'"
></avl-table-loading-placeholder>
<avl-table-no-data-disclaimer
    *ngIf="!isLoading && !titles.length"
    iconName="land-registry-logo-grey"
    message="No titles found"
></avl-table-no-data-disclaimer>
