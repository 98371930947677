<div class="collection__table-container">
    <div class="collection__table-wrap">
        <table
            class="collection__table"
            mat-table
            matSort
            matSortDisableClear
            [trackBy]="trackPurchasedFiles"
            [dataSource]="filesSource"
        >
            <!-- Selector Info Column -->
            <ng-container matColumnDef="selector-info">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    colspan="7"
                >
                    <avl-selection-toolbar [total]="pagination.totalCount">
                    </avl-selection-toolbar>
                </th>
            </ng-container>

            <!-- Document Column -->
            <ng-container matColumnDef="document">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                >
                    Document
                </th>
                <td
                    mat-cell
                    class="nowrap"
                    *matCellDef="let element"
                >
                    {{ element.kind | documentType }}
                </td>
            </ng-container>

            <!-- Reference Column -->
            <ng-container matColumnDef="reference">
                <th
                    mat-header-cell
                    mat-sort-header
                    [class.sortable]="sort.active === 'reference'"
                    *matHeaderCellDef
                >
                    Reference
                </th>
                <td
                    mat-cell
                    class="nowrap"
                    [class.sortable]="sort.active === 'reference'"
                    *matCellDef="let element"
                >
                    {{ element.reference }}
                </td>
            </ng-container>

            <!-- Date Purchased Column -->
            <ng-container matColumnDef="purchasedAt">
                <th
                    mat-header-cell
                    mat-sort-header
                    [class.sortable]="sort.active === 'purchasedAt'"
                    *matHeaderCellDef
                >
                    Date Purchased
                </th>
                <td
                    mat-cell
                    class="nowrap"
                    [class.sortable]="sort.active === 'purchasedAt'"
                    *matCellDef="let element"
                >
                    {{ element.purchasedAt | date:'d MMMM y' }}
                </td>
            </ng-container>

            <!-- Entry Date Column -->
            <ng-container matColumnDef="date">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                >
                    Entry Date
                </th>
                <td
                    mat-cell
                    class="nowrap"
                    *matCellDef="let element"
                >
                    {{ element.date | date:'d MMMM y' }}
                </td>
            </ng-container>

            <!-- Project Name Column -->
            <ng-container matColumnDef="project">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                >
                    Project Name
                </th>
                <td
                    mat-cell
                    class="nowrap"
                    *matCellDef="let element"
                >
                    {{ element.projectName }}
                </td>
            </ng-container>

            <!-- Client/Matter Column -->
            <ng-container matColumnDef="matter">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                >
                    Client / Matter
                </th>
                <td
                    mat-cell
                    class="nowrap"
                    *matCellDef="let element"
                >
                    {{ element.matterNumber }}
                </td>
            </ng-container>

            <!-- Entry Number Column -->
            <ng-container matColumnDef="entryNumber">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                >
                    Entry Number
                </th>
                <td
                    mat-cell
                    class="nowrap"
                    *matCellDef="let element"
                >
                    {{ element.entryNumber }}
                </td>
            </ng-container>

            <!-- Select Column -->
            <ng-container matColumnDef="select">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                >
                    <label
                        *ngIf="files.length"
                        class="collection__add-all-control"
                    >
                        <input
                            class="checkbox-input"
                            type="checkbox"
                            (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                        >
                        <mat-icon svgIcon="add-all-checkbox"></mat-icon>
                        <span class="checkbox-text">Add All</span>
                    </label>
                </th>
                <td
                    *matCellDef="let row"
                    mat-cell
                >
                    <label class="collection__add-control">
                        <input
                            class="checkbox-input"
                            type="checkbox"
                            (click)="$event.stopPropagation()"
                            (change)="$event ? toggleSelection(row) : null"
                            [checked]="isSelected(row)"
                        >
                        <div class="collection__add-label"></div>
                    </label>
                </td>
            </ng-container>
            <tr
                mat-header-row
                *matHeaderRowDef="displayedColumns"
            ></tr>
            <ng-container *ngIf="selection.hasValue() && isAllSelected()">
                <tr
                    mat-header-row
                    *matHeaderRowDef="['selector-info']"
                ></tr>
            </ng-container>
            <tr
                mat-row
                [hidden]="loading"
                *matRowDef="let row; columns: displayedColumns;"
            ></tr>
        </table>
        <avl-table-loading-placeholder
            *ngIf="loading"
            [columns]="displayedColumns"
            [size]="tableRowsAmount"
        ></avl-table-loading-placeholder>
    </div>
    <avl-table-no-data-disclaimer
        *ngIf="!loading && !files.length"
        [iconName]="'archive-grey'"
        [message]="'No purchased files found'"
    ></avl-table-no-data-disclaimer>
    <mat-paginator
        [length]="pagination.totalCount"
        [class.hidden]="!files.length"
        [pageSize]="tableRowsAmount"
        [hidePageSize]="true"
    ></mat-paginator>
</div>
