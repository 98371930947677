import { Injectable } from '@angular/core';
import { Order, QueryEntity, SelectAllOptionsD } from '@datorama/akita';
import { DocumentsState, DocumentsStore } from './documents.store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IDocument } from '../../types';

@Injectable()
export class DocumentsQuery extends QueryEntity<DocumentsState> {
    constructor(protected store: DocumentsStore) {
        super(store);
    }

    public isEmpty(): boolean {
        return this.getAll().length < 1;
    }

    public selectAllWithCorrectOrder(options: SelectAllOptionsD<IDocument>): Observable<IDocument[]> {
        const reversedOrder = this.reverseSortingOrder(options.sortByOrder);

        return this.selectAll({ ...options, sortByOrder: reversedOrder })
            .pipe(
                map((documents) => documents.sort((el) => el.isProcessed ? -1 : 1)),
            );
    }

    private reverseSortingOrder(targetOrder: Order): Order {
        switch (targetOrder) {
            case Order.ASC:
                return Order.DESC;
            case Order.DESC:
            default:
                return Order.ASC;
        }
    }
}
