export const environment = {
    production: true,
    dev: true,
    forceLogLevelDebug: true,
    isRosEnabled: false,
    forceImanageEnabled: false,
    authIntercept: true,
    basicAuth: false,
    authGuard: true,
    api: 'api/titles',
    cloudFunctions: 'https://europe-west2-internal-identity.cloudfunctions.net',
    signInPopup: true,
    googleApiKey: 'AIzaSyDa9tu-mrQymrlWRz7j8iY46nCiamKwEmQ',
    firebase: {
        apiKey: 'AIzaSyAfoEt0phNGL5B0O_JBz-bXEBPxlLIbugc',
        authDomain: 'internal-identity.firebaseapp.com',
    },
    enableIntercom: true,
    intercomModule: {
        appId: 'j5hpcgz2', // from your Intercom config
        updateOnRouterChange: true, // will automatically run `update` on router event changes. Default: `false`
    },
    intercomData: {
        appId: 'j5hpcgz2',
        apiBase: 'https://api-iam.eu.intercom.io',
        // Supports all optional configuration.
        widget: {},
        customLauncherSelector: '#support',
        hideDefaultLauncher: true,
        userHash: null,
    },
    enableAddSchedules: true,
    sentryData: {
        dsn: 'https://f8b29fb0a19de041d34ae7ecac1cd53c@o4504039649968128.ingest.sentry.io/4505917707386880',
        isEnabled: true,
        isTracingEnabled: false,
        tracesSampleRate: 0.3
    },
    imanageConfig: {
        imanageUrl: 'https://cloudimanage.com',
        clientId: 'd68caacf-e36d-4d46-868a-070a394a4ef3',
        redirectUri: 'http://localhost/'
    },
    mapSearch: {
        styleUrl: '/assets/map-settings/OS_VTS_3857_Light_Dev.json',
        stylesUrlOrigin: null,
    },
    isTitleDocumentsReorderingEnabled: false,
    isWhat3WordsSearchEnabled: true,
};
